import React from "react";

import { Trans, useTranslation } from "react-i18next";

import main from '../../img/AccountTypes/main.png'

import Fade from "react-reveal/Fade";

import { Link } from "react-router-dom"

function Main () {

    const { t } = useTranslation();
 
    return(
        <div className="xl:pt-[100px] relative overflow-hidden h-[708px]">
            <img src={main} alt="" className="w-full h-full object-cover hidden xl:block absolute"/>
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto relative mt-[100px] xl:mt-[115px]">
                <Fade duration={2000}>
                    <p className="text-[#2F5D52] open-sans font-[700] xl:text-[35px] text-[25px]"><Trans>{t('accounttypes.1')}</Trans></p>
                    <p className="mt-[30px] xl:max-w-[525px] text-[14px] xl:text-[16px] text-[#232323] open-sans">{t('accounttypes.2')}</p>
                    <p className="mt-[15px] xl:max-w-[525px] text-[14px] xl:text-[16px] text-[#232323] open-sans">{t('accounttypes.3')}</p>
                    <Link to="https://user.millennium-trading.xyz/signup"><div className="cursor-pointer button2 !bg-[#232323] flex mt-[40px] xl:mt-[50px]">
                        <p className="text-white open-sans text-[14px] xl:text-[16px] font-[700]">{t('main.2')}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M5 12H19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 5L19 12L12 19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div></Link>
                </Fade>
            </div>
        </div>
    );
}

export default Main;