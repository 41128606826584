import React from "react";

import { useTranslation } from "react-i18next";

import Fade from 'react-reveal/Fade'

import m1 from '../../img/Promotions/main1.png'
import m2 from '../../img/Promotions/main2.png'
import m3 from '../../img/Promotions/main3.png'

import { Link } from "react-router-dom"

function Main () {

    const { t } = useTranslation();
 
    return(
        <div className="xl:pt-[100px]">
            <div className="max-w-[1280px] xl:mx-auto mx-[20px] relative overflow-hidden flex flex-col">
                <div className="xl:absolute top-[55px] mt-[85px] xl:mt-0 left-1/2 xl:-translate-x-1/2 flex flex-col items-center">
                    <p className="text-[20px] xl:text-[35px] text-[#2F5D52] open-sans font-[700]">{t('promotions.1')}</p>
                    <p className="text-[16px] xl:text-[30px] open-sans text-[#232323] mt-[15px]">{t('promotions.2')}</p>
                    <Link to="https://user.millennium-trading.xyz/signup"><div className="cursor-pointer button2 flex mt-[20px] xl:mt-[30px]">
                        <p className="text-white open-sans text-[14px] xl:text-[16px] font-[700]">{t('main.2')}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M5 12H19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 5L19 12L12 19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div></Link>
                </div>
                <div className="flex xl:mx-auto items-start justify-between pt-[40px] xl:pt-[165px]">
                    <Fade left>
                        <img src={m1} alt="" className="w-[160px] xl:w-full"/>
                    </Fade>
                    <Fade>
                        <img src={m2} alt="" className="pt-[160px] hidden md:block w-[160px] xl:w-full"/>
                    </Fade>
                    <Fade right>
                        <img src={m3} alt="" className="w-[160px] xl:w-full"/>
                    </Fade> 
                </div>
            </div>
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col-reverse justify-between xl:flex-row pt-[80px] xl:pt-[200px]">
                <div className="xl:max-w-[525px]">
                    <p className="open-sans text-[#232323] text-[14px] xl:text-[16px]">{t('promotions.3')}</p>
                    <p className="open-sans text-[#232323] text-[14px] xl:text-[16px] mt-[15px]">{t('promotions.4')}</p>
                    <p className="open-sans text-[#232323] text-[14px] xl:text-[16px] mt-[15px]">{t('promotions.5')}</p>
                    <Link to="https://user.millennium-trading.xyz/signup"><div className="cursor-pointer button2 flex mt-[20px] xl:mt-[50px]">
                        <p className="text-white open-sans text-[14px] xl:text-[16px] font-[700]">{t('main.2')}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M5 12H19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 5L19 12L12 19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div></Link>
                </div>
                <div className="xl:max-w-[525px]">
                    <p className="open-sans text-[#232323] text-[14px] xl:text-[16px] mt-[15px] xl:mt-0">{t('promotions.6')}</p>
                    <p className="open-sans text-[#232323] text-[14px] xl:text-[16px] mt-[15px]">{t('promotions.7')}</p>
                    <p className="open-sans text-[#232323] text-[14px] xl:text-[16px] mt-[15px]">{t('promotions.8')}</p>
                </div>
            </div>
        </div>
    );
}

export default Main;