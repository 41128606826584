import React from "react";

import { useTranslation } from "react-i18next";

import main from '../../img/Oil/main.png'
import circle from '../../img/Home/circle.png'

import Rotate from 'react-reveal/Rotate'
import Fade from 'react-reveal/Fade'

import { Link } from "react-router-dom"

function Main () {

    const { t } = useTranslation();
 
    return(
        <div className="xl:pt-[100px] relative overflow-hidden">
            <div className="max-w-[1280px] relative xl:mx-auto mx-[20px] flex flex-col xl:flex-row justify-between">
                <div className="flex flex-col items-center xl:items-start">
                    <p className="mt-[100px] xl:mt-[155px] open-sans text-[#2F5D52] text-[20px] xl:text-[35px] font-[700]">{t('oil.1')}</p>
                    <p className="mt-[20px] xl:mt-[30px] open-sans text-[#232323] max-w-[525px] font-[400] text-[14px] xl:text-[16px] leading-[1.5] text-center xl:text-left">{t('oil.2')}</p>
                    <p className="mt-[20px] open-sans text-[#232323] max-w-[525px] font-[400] text-[14px] xl:text-[16px] leading-[1.5] text-center xl:text-left">{t('oil.3')}</p>
                    <p className="mt-[20px] open-sans text-[#232323] max-w-[525px] font-[400] text-[14px] xl:text-[16px] leading-[1.5] text-center xl:text-left">{t('oil.4')}</p>
                    <Link to="https://user.millennium-trading.xyz/signup"><div className="cursor-pointer button2 flex mt-[40px] xl:mt-[50px]">
                        <p className="text-white open-sans text-[14px] xl:text-[16px] font-[700]">{t('main.2')}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M5 12H19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 5L19 12L12 19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div></Link>
                </div>
                <div>
                    <Fade right>
                        <img src={main} alt="" className="xl:absolute right-[60px] top-[200px] z-[500] mt-[50px] xl:mt-0"/>
                    </Fade>
                    <Rotate duration={1500}>
                        <img src={circle} alt="" className="absolute xl:relative top-[550px] xl:top-[40px] -z-10"/>
                    </Rotate>
                </div>
            </div>
        </div>
    );
}

export default Main;